<!--
 * @Description: ================== 联系我们 =================
 * @Date: 2022-05-13 18:08:07
 * @Author: Zero
 * @FilePath: \guobao-web\src\components\Contact.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-05-04 15:43:38
-->
<template>
  <div class="page page-game">
    <h2 class="title">联系我们</h2>
    <pre>
如果您对本游戏有任何疑问、意见或建议。
请联系我们：
邮箱：【yqeeik@sina.com】与我们客服人员联系。
    </pre>
  </div>
</template>
